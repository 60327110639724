%expanded-menu-link {
   padding: .5rem 0;
	color: $white;
	transition: $slow;
	display: inline-block;
	@include tablet {
		padding: .25rem .4rem;
		font-size: 1rem;
	}
	@include md {
		font-size: 1.2rem;
	}		
	&:hover {
		background: $orange;
		color: $darkgray;
	}
	&:focus {
  	box-shadow:  0 0 0 2px $white;
	}
}
.expanded-menu {
	background: $blue;
	@include size(70vw, 100vh);
	@include fixed(top 0 right 0);
	z-index: 100000;	
	transition: $slow;
	transform: translateX(100%);
	visibility: hidden;		
	box-shadow: -.5rem 0 1.5rem 0 rgba(0,0,0,.25);
	overflow-y: scroll;
		
	@include tablet {
		@include size(100%);
		@include fixed(top 0 left 0);
		background: $blue;
		padding: 0;
		opacity: 0;
		padding: 1rem;
		transform: translateX(0);		
	}
	@include md {
		padding: 2rem;
	}	
	&-grid {
		margin: 0 auto;
      padding-top: 1rem;
		@include tablet {
         padding: 0;
         background: $blue;
         -webkit-box-shadow:inset 0 0 0 1px rgba(255,255,255,1);
         box-shadow:inset 0 0 0 1px rgba(255,255,255,1);
			width: 48em;
			max-width: 90%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			align-items: stretch;			
		}	
		@include desktop {
			width: 64em;
		}			
	}
	&-col {
		margin: 0;
		text-align: left;
		font-family: $headline2;
		@include tablet {
			opacity: 0;
			transform: translateY(3rem);			
			transition: $slow;	
			flex: 1;
			padding: 1.5rem;				
		}
		@include md {			
			padding: 2rem;
		}
		& .expanded-menu-nav-link {
			color: $white;
         background: rgba(255,255,255,.1);
         margin-bottom: 1px;
			display: block;
			padding: .5rem 2rem;
			transition: $slow;
			font-size: 1rem;
			@include tablet {
            background: none;
   		   padding: .65rem 1.0rem;
				font-size: 1rem;
			}
			@include md {
				font-size: 1.3rem;
			}				
			&:hover {
				background: $orange;
            color: $darkgray;
            @include tablet {
               background: $orange;
               color: $darkgray;
            }
			}
			&:focus {
     	      box-shadow: 0 0 0 2px $white;
			}
      }

	}		
	.social {
		margin: 1rem 0 0 1rem;
		@include tablet {
			margin: 1rem auto 0;
		}		
		&-link {
			@include size(2.5rem);
			padding: .5rem;
         display: inline-block;
			@include tablet {
				@include size(1.75rem);
				padding: .25rem;				
			}		
			@include md {
				@include size(2.5rem);
				padding: .5rem;
			}				
		}
		&-icon {
         padding: .25rem;
			fill: $white;
         transition: $slow;
			&:hover {
				fill: $red;
            background: $orange;
			}
		}
	}
}
.mobile-menu-close {
	@include size(100%, 100%);	
	@include fixed(top 0 left 0);
	background: rgba($black, .7);
	display: block;
	z-index: 10000;
	opacity: 0;
	visibility: hidden;
	transition: $slow;
}
.display-menu {
	overflow: hidden;
	.expanded-menu {
		transform: translateX(0);
		visibility: visible;
		@include size(85vw, 100vh);
		@include tablet {
			@include size(100vw, 100vh);
			opacity: 1;
			visibility: visible;
		}
	}
	&.reveal-items {
		.expanded-menu {
			&-col,
			&-search {
				@include tablet {
					transition: all .6s cubic-bezier(.5,1.65,.4,.8);	
					opacity: 1;
					transform: translateY(0);					
				}
			}
			&-col {
				@include tablet {
					&:nth-child(2){
						transition-delay: .2s !important;
					}	
					&:nth-child(3){
						transition-delay: .4s !important;
					}				
					&:last-child {
						transition-delay: .6s !important;
					}			
				}	
			}
		}
	}
	.mobile-menu-close {
		opacity: .6;
		visibility: visible;
	}	
}
.expanded-menu-section-header.expanded  {
	.expanded-menu-section-header-link {
		color: $white;
	}	
	.expanded-menu-section-header-arrow-svg {
		transform: rotate(180deg);
	}
}	
