.menu-trigger {
   opacity: 1;
   visibility: visible;
   background: transparent;
	border: 0;	
	position: fixed;
	right: .25rem;
	top: 1rem !important;
	z-index: 2000;
	padding: .75rem .75rem .5rem;
	font-family: $nav;
	transition: $slow;
	transform-origin: top left;
	display: inline-block;
	cursor: pointer;
	@include tablet {
		z-index: 200000;
      top: 1.6rem !important;
	}	
   @include desktop {
      opacity: 0;
      visibility: hidden;
   }
	&:hover {
		.hamburger-inner {
         width: 18px;
			&, 
			&:before,
			&:after {
				background-color: $orange;
			}
			&:before,
			&:after {
				width: 26px;
			}
		}
		.menu-trigger-label {	
			color: $orange;
			transition: $bounce;			
		}
	}
	&:focus {
		box-shadow: inset 0 0 0 2px $white;
	}
}
.fixedxxx .menu-trigger {
   left: 1rem;
   opacity: 1.0;
   visibility: visible;
   transition: $slow;
   @include tablet {
		left: 2rem;
	}	
   @include md {
   	left: 3rem;
	}	
   @include lg {
   	left: 4rem;
	}	
}
.home .menu-trigger {
   top: 1rem !important;
   @include tablet-wide {
      top: 1.5rem !important;
	}	

}

.hamburger {
	padding: 0 .15rem;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	position: relative;
	transition: $basic;
	vertical-align: middle;
	z-index: 10000;
	&-box {
		width: 26px;
		height: 14px;
		display: inline-block;
		position: relative;
	}
	&-inner {
		display: block;
		top: 50%;
		width: 20px; 
		margin-top: -1px;
		transition-duration: .22s;
		transition-timing-function: cubic-bezier(.55, .055, .675, .19); 	
		&, &:before, &:after {
			height: 2px;
			background-color: lighten($white, 25%);
			border-radius: 0;
			position: absolute;
			transition-property: transform, width, background-color;
			transition-duration: 0.15s;
			transition-timing-function: ease; 
		}
		&:before,
		&:after {
			width: 20px;
			content: "";
			display: block;
		}
		&:before {
			top: -6px; 
			transition: top .1s .25s ease-in, opacity .1s ease-in, width .1s .12s ease-out; 

		}
		&:after {
			bottom: -6px; 
			transition: bottom .1s .25s ease-in, transform 0.22s cubic-bezier(.55, .055, .675, .19), width .1s .12s ease-out; 			
		}	
	 }	
 }
.menu-trigger-label {
	vertical-align: middle;
   top: -1px;
   position: relative;
   display: inline-block;
   text-transform: uppercase;
   color: $white;
	&.menu-label {
		clip-path: inset(0 0 0 0);
		transition: color .3s cubic-bezier(.57,.2,.21,.89), clip-path .3s cubic-bezier(.57,.2,.21,.89) .5s !important, opacity .3s cubic-bezier(.57,.2,.21,.89) .5s !important;	
	}
	&.close-label {
		position: absolute;
      left: calc(18px + 1rem);
      top: 50%;
		transform: translateY(-50%);
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
		clip-path: inset(0 100% 0 0);	
		transition: color .3s cubic-bezier(.57,.2,.21,.89), clip-path .3s cubic-bezier(.57,.2,.21,.89) 0 !important;	
	}
	@include ie {
		&.close-label {
			display: none;
		}
	}
	@include edge {
		&.close-label {
			display: none;
		}
	}	
}
.display-menu {
	.menu-trigger {	
		transform: scale(1.0);
		.hamburger-inner {		
			&, 
			&:before,
			&:after {
				transition: $slow;
				background-color: $white;
				@include tablet {
					background-color: $white;
				}
			}
		}		
		&:hover {
			.hamburger-inner {		
				&, 
				&:before,
				&:after {
					@include tablet {
						background-color: $white;
					}
				}
			}	
			.menu-trigger-label {
				color: $white;
			}			
		}	
	}
	.hamburger {
		&-inner {
			transform: rotate(225deg);
			transition-delay: .12s;
			transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
			width: 1826px; 			
			&,
			&:before,
			&:after {
				width: 18px !important;
				background-color: $white;
				@tablet {
					background-color: $white;
				}
			}
			&:before {
				top: 0;
				opacity: 0;
				transition: top .1s ease-out, opacity .1s .12s ease-out;
			}
			&:after {
				bottom: 0;
				transform: rotate(-90deg);
				transition: bottom .1s ease-out, transform 0.22s 0.12s cubic-bezier(.215, .61, .355, 1);
			}			
		}	
	}
	.menu-trigger-label {
		color: $black;
		@include phone {
			opacity: 0;
			visibility: hidden;
		}
		&.menu-label {
			clip-path: inset(0 100% 0 0);
			transition: color .2s cubic-bezier(.57,.2,.21,.89), clip-path .3s cubic-bezier(.57,.2,.21,.89) .0s !important;	
    	@include edge {
  			display: none;
    	}					
		}	
		&.close-label {
         font-size: 1.1rem;
         font-family: $nav;
			clip-path: inset(0 0 0 0);
			transition: color .5s cubic-bezier(.57,.2,.21,.89), clip-path .3s cubic-bezier(.57,.2,.21,.89) .5s !important;
         opacity: 1;
         visibility: visible;
         color: white;
    	   @include edge {
  			   display: inline-block;
    	   }				
		}			
	}
	&.reveal-items {
		@include tablet {
			.menu-trigger {
				transform: scale(1.0);
            right: 2rem;
			}	
		}		
	}	
}
.fixed {
	.menu-trigger {
		transition: $slow;
	}	
	&.reveal-items {
		@include tablet {
			.menu-trigger {
				transform: scale(1.0) translateX(0);
			}	
		}		
	}		
}
.menu-trigger.fixed-menu {
	transform: scale(1.0) translateX(0);
}	
.home.fixed .menu-trigger {
   transition: $slow;
   top: 0.75rem !important;
   @include tablet {
      top: 1.6rem !important;
   }
}
