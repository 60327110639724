.video-item {
   width: 100%;
   text-align: center;
   &-embed {
      margin: 0 auto;
      max-width: 100%;
      @include tablet {
         max-width: $phone;
      }
      @include desktop {
         max-width: $tablet-wide;
      }
   }
   &-text {
      &-headline {
         font-family: $headline2 !important;
         @extend %headline4;
         color: $white;
      }
   }
}

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
