%footer-link {
	color: $medgray;
	transition: $basic;
	margin: 0 auto;
	&:hover {
		color: $orange;
	}
	&:focus {
      color: $blue;
      box-shadow: 0 0 0 1px $orange;
	}	  	
	@include tablet {
		font-size: .8rem;
	}
	@include desktop {
		font-size: 1rem;
	}	
}
.site-footer {
   border-top: 8px $gray solid;
   background: $lightgray; 
   padding: 2rem 0;
	&-grid {
	   @extend %block-padding;
		margin: 0 auto;
   	@include tablet {
         display: grid;
         grid-template-columns: repeat(5, 1fr);
         grid-template-rows: repeat(3, 1fr);
         grid-column-gap: 0px;
         grid-row-gap: 0px;
		}
      & .div1 { 
         grid-area: 1 / 1 / 2 / 6; 
         @include desktop { grid-area: 1 / 1 / 2 / 2; }
      }
      & .div2 { 
         grid-area: 2 / 1 / 3 / 2; 
         @include desktop { grid-area: 1 / 2 / 2 / 3; }
      }
      & .div3 { 
         grid-area: 2 / 2 / 3 / 3; 
         @include desktop { grid-area: 1 / 3 / 2 / 4;  }
      }
      & .div4 { 
         grid-area: 2 / 3 / 3 / 4; 
         @include desktop{ grid-area: 1 / 4 / 2 / 5; }
      }
      & .div5 { 
         grid-area: 2 / 4 / 3 / 5; 
         @include desktop { grid-area: 1 / 5 / 2 / 6; }
      }
      & .div6 { 
         grid-area: 3 / 1 / 4 / 5; 
         margin-top: 2rem;
         @include desktop { margin-top: 0; grid-area: 1 / 7 / 2 / 8; }
      }
		@include tablet-wide {
         display: grid;
         grid-template-columns: repeat(4, 1fr);
         grid-template-rows: repeat(2, 1fr);
         grid-column-gap: 10px;
         grid-row-gap: 10px;
		}	
		@include desktop {
			max-width: $desktop-medium;
			grid-template-rows: repeat(1, 1fr);
		}			
	}
	&-col {
		text-align: center;
		margin: 0 auto;
      padding: 0 0 1em 0;
		@include tablet {
		   text-align: left;
			margin: 0 2rem 0 0;	
         padding: 0;
		}
      
	}	
	&-nav-list {
		text-align:  center;
      list-style: none;
      margin: 0;
      padding: 0;
		&-link {
			@extend %footer-link;
			display: block;
			margin: 0 0 .5rem 0;
         font-weight: 400;
			@include tablet {
            font-size: 0.9rem;
            display: table;
			}
         &-title {
            color: $darkgray;
            font-family: $nav;
            font-weight: 900;
            letter-spacing: -.5px;
            font-size: 1.1rem;
            padding: 0 0 .5rem 0;
            text-transform: uppercase;
            display: block;
            &:focus {
               color: $blue;
               box-shadow: 0 0 0 1px $orange;
            }	  	
            &:hover {
               color: $orange;
            }
         }
		}
      @include tablet {
         text-align: left;
      }
	}
	.social {
		margin: 1.5rem 0 1.5rem 0;
		@include tablet {
			margin-bottom: 0;
			margin: 1rem 0 1rem 0;			
		}
		@include desktop {
			margin: 1.5rem 0 1.5rem 0;			
		}				
		&-link {
			@include size(1.75rem, 1.75rem);
			@include tablet {
				@include size(1.35rem, 1.35rem);				
			}	
			@include desktop {
				@include size(1.75rem, 1.75rem);
			}				
		}
	}
   &-callout {
      color: $darkgray;
      font-family: $nav;
      letter-spacing: -.5px;
      font-weight: 900;
      font-size: 1.1rem;
      border: 2px $darkgray solid;
      padding: .5rem .75rem;
      margin: 0 0 .5rem 0;
      text-transform: uppercase;
      display: block;
      &:hover {
         color: $orange;
         border: 2px $orange solid;
      }
   }
}

.footer-extras-share {
   font-size: 1.0rem;
   margin-top: .5rem;
   position: relative;
   @include md {
  	font-size: 1.1rem;
	}
}

.footer-extras-share .social-link {
   margin-right: 0.2rem;
   display: inline-block;
   border-radius: 0;
   padding: 0;
   width: 20px;
}
.footer-extras-share .social-link svg {
   width: 100%;
   fill: $medgray;
}
.footer-extras-share .social-link:hover svg {
   fill: $orange;
}

.paidfor {
	padding: .75rem;
	line-height: 1.3;
	border: 1px solid rgba($gray,.4);
	margin-bottom: 1.25rem;
	@include tablet {
		font-size: .7rem;
	}	
	@include desktop {
		font-size: 1rem;
	}		
}

.copyright {
   width: 100%;
   
   text-align: left;

	&-content {
		padding: 2rem;
      color: $darkgray;
      margin: 0 auto;
		@include tablet {
			font-size: .75rem;
		}	
		@include desktop {
			font-size: 0.8rem;
         max-width: $desktop-medium;
		}			
	}
	&-nav {
		&-link {
         color: $darkgray;
         font-size: 0.8rem;
         font-weight: 700;
			@extend %footer-link;
			display: inline-block;
		}
	}
}
